/* eslint-disable no-unused-vars */
import useUser from "../hooks/useUser";
import supabase from "../supabase/client";
import axios from "axios";
import cookieCutter from "cookie-cutter";
import { useEffect } from "react";

const signInSupabase = async (email, token) => {
  try {
    const { data } = await axios.get(
      process.env.NEXT_PUBLIC_DIRECTUS_URL +
        "/users/me?fields=sppas&access_token=" +
        token
    );
    const { user, session, error } = await supabase.auth.signIn({
      email,
      password: data.data.sppas,
    });
  } catch (error) {
    console.log(error);
  }
};

const signInSupabaseAnonymous = async () => {
  try {
    const { data } = await axios.get("/api/anonymous/get");
    await supabase.auth.signIn({
      email: data.email,
      password: data.password,
    });
  } catch (error) {
    console.log(error.reponse);
  }
};

export const SupabaseUser = ({ pageProps, children }) => {
  const { user, token } = useUser();

  useEffect(() => {
    if (Boolean(user) && Boolean(!supabase.auth.user())) {
      signInSupabase(user.email, token);
    } else if (Boolean(!user) && Boolean(!supabase.auth.user())) {
      const anonUser = cookieCutter.get("agrowy-anonuser-exists");
      if (anonUser) {
        try {
          signInSupabaseAnonymous();
        } catch (error) {
          console.log(error);
        }
      }
    }
  }, [user, token]);

  return <>{children}</>;
};
