// All atom pieces
import {
  deleteConfirmationPopupAtom,
  globalSearchPopupAtom,
  jobApplicationPopupAtom,
  loginPopupAtom,
  productRequestPopupAtom,
} from "../../atoms/popupAtoms";
import useUser from "../../hooks/useUser";
import { useAtom } from "jotai";
import dynamic from "next/dynamic";

// Dynamic Imports
const LoginPopup = dynamic(() => import("../elements/ui/LoginPopup"));
const DeleteConfirmationPopup = dynamic(() =>
  import("../elements/ui/DeleteConfirmationPopup")
);
const ProductRequestPopup = dynamic(() =>
  import("../elements/ui/ProductRequestPopup")
);
const ChatBoxPopup = dynamic(() => import("../elements/ui/ChatBoxPopup"));
const JobApplicationPopup = dynamic(() =>
  import("../elements/ui/JobApplicationPopup")
);
const GlobalSearchPopup = dynamic(() =>
  import("../elements/ui/GlobalSearchPopup")
);

const PopupContainer = ({ children }) => {
  // All States
  const [loginPopup] = useAtom(loginPopupAtom);
  const [deleteConfirmationPopup] = useAtom(deleteConfirmationPopupAtom);
  const [productRequestPopup] = useAtom(productRequestPopupAtom);
  const [jobApplicationPopup] = useAtom(jobApplicationPopupAtom);
  const [globalSearchPopup] = useAtom(globalSearchPopupAtom);
  const { user, token, loading } = useUser();

  return (
    <>
      {loginPopup && <LoginPopup />}
      <ChatBoxPopup />
      {deleteConfirmationPopup && <DeleteConfirmationPopup />}
      {productRequestPopup.show && (
        <ProductRequestPopup user={user} token={token} loading={loading} />
      )}
      {jobApplicationPopup.show && (
        <JobApplicationPopup user={user} token={token} loading={loading} />
      )}
      {globalSearchPopup.show && <GlobalSearchPopup />}
      {children}
    </>
  );
};

export default PopupContainer;
