import axios from "axios";
import { useSession } from "next-auth/react";
import useSWR from "swr";

export default function useUser() {
  const { data: session, status } = useSession();

  const token = session?.accessToken;

  const userFetcher = async () => {
    const res = await axios.get(
      process.env.NEXT_PUBLIC_DIRECTUS_URL +
        "/users/me?fields=id,first_name,last_name,verified,bio,mini_bio,title,email,company_text,mobile,persona,locale,slug,title,avatar,onboarding,city,city_iso2,country,country_iso2,contact_preference,contact_preference_saved,facebook,twitter,instagram,linkedin,youtube,active_production,plants.id,plants.plants_id.*&access_token=" +
        token
    );
    if (res.data) {
      return res.data.data;
    } else {
      return null;
    }
  };

  const {
    data: user,
    mutate,
    error,
    isValidating,
  } = useSWR(status === "authenticated" ? token : null, userFetcher);

  const loading =
    (status === "loading" || Boolean(!user)) &&
    !error &&
    status != "unauthenticated";
  const authenticated =
    status === "authenticated" && Boolean(user) && !session?.error;
  const unauthenticated =
    Boolean(status === "unauthenticated") || session?.error;

  return {
    loading,
    authenticated,
    user,
    token,
    unauthenticated,
    mutate,
    isValidating,
    error,
  };
}
