import PopupContainer from "../components/layouts/PopupContainer";
import { SupabaseUser } from "../context/supabaseContext";
import "../styles/globals.css";
import { SessionProvider } from "next-auth/react";
import { GoogleTagManager } from '@next/third-parties/google'
import { Toaster } from "react-hot-toast";

function MyApp({ Component, pageProps }) {
  return (
    <SessionProvider session={pageProps.session}>
      <SupabaseUser>
        <PopupContainer />
        <Toaster
          position="top-center"
          toastOptions={{
            success: {
              iconTheme: {
                primary: "#66E78E",
                secondary: "white",
              },
            },
            error: {
              iconTheme: {
                primary: "#DC2626",
                secondary: "white",
              },
            },
            className: "",
            style: {
              borderRadius: "6px",
            },
          }}
        />

        <Component {...pageProps} />
      </SupabaseUser>
      <GoogleTagManager gtmId="GTM-PN25F6G5" />
    </SessionProvider>
  );
}

export default MyApp;
